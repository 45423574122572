import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell i peu. El capell primerament és hemisfèric, posteriorment convex acabant pla, de color marró més o menys obscur. És un poc viscós en temps humit. El peu és llarg, cilíndric i un poc gruixut a la base, de color groc a la part superior i amb punts marrons i rogencs a la resta. Té tubs grocs de la mateixa manera que els porus. Les espores són de color ocre grogós en massa, fusiformes, de 7-9 x 3-4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      